import React from "react";

const PrivacyPolicy = () => {

    return (
        <>
            Privacy policy here
        </>
    );
};
export default PrivacyPolicy;